<template>
  <div>
    <div class="top" @click="Jump('/userdata')">
      <div class="left">
        <div class="img-box">
          <img :src="headerImg" alt="" class="left-img">
        </div>
        <div class="left-phone">{{phone}}</div>
      </div>
        <van-icon name="arrow" size="14" color="c6cdd6" />
    </div>
    <div class="line-box">
      <img src="@/assets/images/user/line.png" alt="" class="line">
    </div>
    <div class="about">
      <p class="pone">关于</p>
      <div class="item-box" @click="Jump('/aboutus')">
        <div class="left-text">关于通信网服中心</div>
        <van-icon name="arrow" size="14" color="c6cdd6" />
      </div>
      <div class="edition">
        <div>版本号</div>
        <div class="edition-right">V 1.01.01</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone:"",  // 电话号码
      headerImg:"", // 用户的头像信息
    }
  },
  mounted(){
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
    this.phone = sessionStorage.getItem("phone")
    this.headerImg = localStorage.getItem("headerImg")
  },
  methods:{
    Jump(item){
      this.$router.push(item)
    }
  }
}
</script>
<style lang="less" scoped>
.top{
  width: 92%;
  height: 130px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  .left{
    display: flex;
    justify-items: center;
    align-items: center;
    .img-box{
      width: 56px;
      height: 56px;
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 2px;
      margin: 0 22px 0 2px;
      .left-img{
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 8px;
      }
    }
    .left-phone{
      font-size: 24px;
      color: #222;
      font-weight: 600;
      font-family: "bahn";
    }
  }
}
.line-box{
  width: 90%;
  margin: 0 auto;
  .line{
  width: 100%;
  display: block;
  margin: 0 auto 34px ;
  height: 1px;
}
}
.about{
  width: 92%;
  margin: 0 auto;
  .pone{
    font-size: 12;
    color: #a7b2bf;
  }
  .item-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    margin-top: 34px;
    .left-text{
      font-size: 16px;
      color: #222222;
      font-weight: 500;
    }
  }
  .edition{
    font-size: 16px;
    color: #222222;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin-top: 38px;
    .edition-right{
      font-size: 14px;
      font-weight: 500;
      color: #b5bec9;
    }
  }
}
</style>